footer.footer {
	margin-top: 50px;
}

.footer-container {
	width: 100%;
	background-color: #132332;
	border-top: 10px solid #2a3946;
}

.footer-logo-container {
	border-right: 3px solid rgba(255, 255, 255, 0.5);
}

.footer-link-container {
	margin-top: 1px;

	& a {
		font-weight: 700;
		font-size: 0.9em;
		color: white !important;
		text-decoration: none !important;
	}

	& a:hover {
		color: white !important;
		cursor: pointer;
		text-decoration: none !important;
	}
}

.footer-copy-container {
	> .footer-copy-text {
		color: white;
		font-size: 0.8em;
	}
}
