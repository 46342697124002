$banner-red: #ffe2e3;
$banner-red-dark: #cf464d;

$banner-orange: #ffe2c0;
$banner-orange-dark: #f7931d;

$banner-green: #d5f7dd;
$banner-green-dark: #66b677;

$banner-blue: #deedf7;
$banner-blue-dark: #193a5a;

$banner-default: #e9eaea;
$banner-default-dark: #b7b7b7;

$banner-white: #fff;

.banner-container {
	background-color: $banner-default;
}

.banner-item {
	background-color: $banner-default;
	border-bottom: 1px solid #ffffff;

	> div > div.banner-icon-container {
		background-color: $banner-default-dark;
		color: #fff;
		min-width: 80px;
		min-height: 80px;

		&.red {
			background-color: $banner-red-dark !important;
		}

		&.orange {
			background-color: $banner-orange-dark !important;
		}

		&.green {
			background-color: $banner-green-dark !important;
		}

		&.blue {
			background-color: $banner-blue-dark !important;
		}

		> div.banner-icon {
			font-size: 34pt;
		}
	}

	> div > div.banner-text-container {
		background-color: #e9eaea;
		font-size: 0.95em !important;
		width: 100%;

		&.red {
			background-color: $banner-red !important;
		}

		&.orange {
			background-color: $banner-orange !important;
		}

		&.green {
			background-color: $banner-green !important;
		}

		&.blue {
			background-color: $banner-blue !important;
		}

		> div.banner-text {
			font-size: 1em !important;
			width: 80%;
		}
	}

	> div > div.banner-close-container {
		background-color: #e9eaea;

		&.red {
			background-color: $banner-red !important;
		}

		&.orange {
			background-color: $banner-orange !important;
		}

		&.green {
			background-color: $banner-green !important;
		}

		&.blue {
			background-color: $banner-blue !important;
		}

		> div.banner-close {
			background-color: $banner-default-dark;
			color: #fff;

			&.red {
				> button {
					background-color: darken($banner-red, 6%) !important;
				}
				> button:hover {
					background-color: darken($banner-red, 12%) !important;
				}
			}

			&.orange {
				> button {
					background-color: darken($banner-orange, 6%) !important;
				}
				> button:hover {
					background-color: darken($banner-orange, 12%) !important;
				}
			}

			&.green {
				> button {
					background-color: darken($banner-green, 6%) !important;
				}
				> button:hover {
					background-color: darken($banner-green, 12%) !important;
				}
			}

			&.blue {
				> button {
					background-color: darken($banner-blue, 6%) !important;
				}

				> button:hover {
					background-color: darken($banner-blue, 12%) !important;
				}
			}
		}
	}
}
