$default-blue: #004060;
$default-grey: #e9eaea;

.sign-in-container {
	border-left: 15px solid $default-blue;
	background-color: $default-grey;
	min-height: 155px;
	margin-bottom: 25px;
	padding: 7px 12px;
}

.sign-in-title {
	margin-top: 25px;
	font-size: 1.4em;
	font-weight: 700;
	color: $default-blue;
}

.sign-in-text {
	font-size: 1em;
	padding: 12px 0 15px 0;
	color: $default-blue;
}

.sign-in-button-container {
	margin-top: 35px;
}

.sign-in-button-container.agent {
	margin-top: 50px !important;
}

.sign-in-button {
	font-size: 1.2em;
	width: 100% !important;
}


.announcement {
	padding: 4px 10px 10px 39px !important;
  }
  
  .announcement-light-blue {
	background-color: #deedf7 !important;
	color: #000 !important;
  }
  
  
  .announcement:before {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	width: 30px;
	height: 30px;
	background: url("../Global/Images/info-white.svg") no-repeat center center;
	background-color: #193a5a;
	background-size: 80%;
  }