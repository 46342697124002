.estimator-content-container {
	background-color: #e9eaea;
	padding: 7px 0px 7px 0px;
	border-left: 3px solid #004060;
}

.estimator-content-container > div {
	padding-left: 12px;
	padding-right: 7px;
}

.estimator-total-border {
	border-top: 2px solid #fff;
	margin-top: 8px;
}

.estimator-multi-container {
	background-color: #f6f6f6 !important;
}

.estimator-multi-error {
	font-size: 0.85em;
	color: #0b7514;
	font-weight: 700;
}

.estimator-sub-head {
	font-size: 0.9em;
	color: grey;
}
