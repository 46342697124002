.title-container {
  margin-bottom: 30px;
}

.title {
  font-size: 2.4em;
}

.title-sub-text {
  font-weight: 700;
  color: #777;
  font-size: 0.95em;
}

.title > svg {
  padding-left: 3px;
  padding-right: 2px;
}

.title > svg.icon {
  color: #dcddde !important;
}/*# sourceMappingURL=Title.css.map */