.timeline-container {
	background-image: url(./Images/timeline_bg.svg);
	background-repeat: no-repeat;
}

.timeline-container > div.horizontal {
	padding-left: 20px;
	padding-right: 20px;
}

.timeline-main-wrapper {
	margin-top: 20px !important;
}

.timeline-main-wrapper > div > div {
	height: 2px !important;
}

.timeline-circle {
	font-size: 1.5em !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
	background: #f3f4f4 !important;
}

.timeline-item-title {
	color: #444 !important;
}

.timeline-item-title.active {
	border-radius: 0 !important;
	background-color: #004060 !important;
	padding: 5px 10px 3px;
	color: #fff !important;
}

.timeline-card-content {
	background: transparent !important;
}

.timeline-controls {
	border-radius: 0 !important;
	background: transparent !important;
}

.timeline-controls > li > button {
	background-color: #004060 !important;
	width: 24px !important;
	height: 24px !important;
}

.card-description {
	max-height: 100% !important;
	margin-top: 0 !important;
}

.timeline-content {
	border-left: 3px solid #444;
	padding: 3px 0 3px 6px;
}

.timeline-content.none {
	border-left: none !important;
	padding: 3px 0 3px 6px;
}

.timeline-content.red {
	border-color: #fa6953;
}

.timeline-content.purple {
	border-color: #bd9fca;
}

.timeline-content.blue {
	border-color: #0088a7;
}

.timeline-content.teal {
	border-color: #42caba;
}

.timeline-content.green {
	border-color: #aabe4c;
}

.timeline-content.yellow {
	border-color: #f5d652;
}

.timeline-content.orange {
	border-color: #f4954f;
}

.timeline-icon {
	color: #e9eaea;
}

.timeline-icon.timeline-transparent {
	color: #e9eaea !important;
}

.timeline-icon.red {
	color: #fa6953;
}

.timeline-icon.purple {
	color: #bd9fca;
}

.timeline-icon.blue {
	color: #0088a7;
}

.timeline-icon.teal {
	color: #42caba;
}

.timeline-icon.green {
	color: #aabe4c;
}

.timeline-icon.yellow {
	color: #f5d652;
}

.timeline-icon.orange {
	color: #f4954f;
}

.timeline-horz-card-wrapper {
	margin-top: 0 !important;
}

@media only screen and (max-width: 1200px) {
	.timeline-container {
		background-image: none !important;
		background-color: #f3f4f4;
		min-height: 380px;
	}
}
