.sign-in-container {
  border-left: 15px solid #004060;
  background-color: #e9eaea;
  min-height: 155px;
  margin-bottom: 25px;
  padding: 7px 12px;
}

.sign-in-title {
  margin-top: 25px;
  font-size: 1.4em;
  font-weight: 700;
  color: #004060;
}

.sign-in-text {
  font-size: 1em;
  padding: 12px 0 15px 0;
  color: #004060;
}

.sign-in-button-container {
  margin-top: 35px;
}

.sign-in-button-container.agent {
  margin-top: 50px !important;
}

.sign-in-button {
  font-size: 1.2em;
  width: 100% !important;
}

.announcement {
  display: block;
  position: relative;
  background-color: #005087;
  color: #fff;
  padding: 10px 10px 10px 40px;
  font-weight: 300;
  font-size: 1em;
  margin: 0;
}


.announcement {
  padding: 4px 10px 10px 39px !important;
}

.announcement-light-blue {
  background-color: #deedf7 !important;
  color: #000 !important;
}


.announcement:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 30px;
  height: 30px;
  background: url("../Global/Images/info-white.svg") no-repeat center center;
  background-color: #193a5a;
  background-size: 80%;
}

/*# sourceMappingURL=Home.css.map */