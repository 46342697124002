.noData {
	padding-bottom: 10px;
}

div.gridjs.gridjs-container {
	color: inherit !important;
	padding: 0 !important;
}

div.gridjs-wrapper {
	border-radius: 0 !important;
}

table.gridjs-table {
	border: none !important;
	width: -webkit-fill-available;
}

div.gridjs-head {
	margin-bottom: 10px;
	padding: 0;
}

div.gridjs-footer {
	background-color: #fff !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	padding: 8px 1px !important;
	border: none !important;
}

div.gridjs-footer > div.gridjs-pagination .gridjs-pages button {
	color: inherit !important;
}

div.gridjs-footer > div.gridjs-pagination .gridjs-pages button:disabled {
	color: #6b7280 !important;
}

div.gridjs-footer > div.gridjs-pagination .gridjs-pages button:first-child,
div.gridjs-footer > div.gridjs-pagination .gridjs-pages button:last-child {
	border-radius: 0 !important;
}

table > thead.gridjs-thead > tr.gridjs-tr:hover {
	background-color: red !important;
}

table > thead.gridjs-thead > tr.gridjs-tr > th.gridjs-th {
	color: #fff !important;
	font-size: 1.2em;
}

table > tbody.gridjs-tbody > tr.gridjs-tr > td.gridjs-td {
	border: none !important;
	color: #444 !important;
}

table > thead.gridjs-thead > tr.gridjs-tr > th.gridjs-th {
	border: none !important;
}

table > thead.gridjs-thead > tr.gridjs-tr > th.gridjs-th {
	background-color: #004060;
}

table > tbody.gridjs-tbody > tr.gridjs-tr:nth-child(even) > td.gridjs-td {
	background-color: #f1f3f5 !important;
}

table > tbody.gridjs-tbody > tr.gridjs-tr:hover > td.gridjs-td {
	background-color: #d9e9f7 !important;
}

table > tbody.gridjs-tbody > tr.gridjs-tr > td.gridjs-td {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
}

.gridjs-wrapper:nth-last-of-type(2) {
	border-bottom: none !important;
}

[data-column-id="sortUp"] {
	padding-left: 24px !important;
	padding-right: 5px !important;
}

[data-column-id="sortDown"] {
	padding-left: 10px !important;
	padding-right: 5px !important;
}

[data-column-id="sortOrder"] {
	padding-left: 10px !important;
	padding-right: 20px !important;
}

button.gridjs-sort {
	width: 18px !important;
	border: none !important;
	background-color: inherit !important;
}

input[type="search"].gridjs-search-input {
	border: 1px #506d86 solid !important;
	border-left: 5px #506d86 solid !important;
	height: 30px !important;
	font-size: inherit;
	color: inherit;
}

input[type="search"].gridjs-search-input:focus {
	box-shadow: 0 0 8px 0 #b3b3b3 !important;
}

.grid-button.xl {
	font-size: 1.3em !important;
}

.grid-button.sort-up {
	position: relative;
	font-size: 1.2em;
	top: 1px;
}

.grid-button.sort-down {
	position: relative;
	font-size: 1.2em;
	top: -1px;
}

.grid-button.red {
	color: #cf464d !important;
}

.grid-button.light-blue {
	color: #00a5d4 !important;
}

.grid-button.blue {
	color: #004060 !important;
}

a.grid-link.view {
	color: #444 !important;
	text-decoration: underline !important;
}

a.grid-link.view:hover {
	color: #23527c !important;
	text-decoration: underline !important;
	cursor: pointer;
}

.grid-badge {
	border: 1px solid #f7931d;
	background-color: #f7931d;
	color: #fff;
	font-weight: 700;
	font-size: 0.8em;
	width: 46px;
	text-align: center;
}

@media (max-width: 500px) {
	[data-column-id="sortUp"] {
		display: none;
	}
	[data-column-id="sortDown"] {
		display: none;
	}
	[data-column-id="displayAsNewFlag"] {
		display: none;
	}
	table > thead.gridjs-thead > tr.gridjs-tr > th.gridjs-th {
		padding: 7px 12px;
	}
	table > tbody.gridjs-tbody > tr.gridjs-tr > td.gridjs-td {
		padding: 6px 12px;
	}
	.gridjs-pages {
		float: left !important;
	}
	.gridjs-summary {
		padding-right: 10px;
	}
}
@media (max-width: 600px) {
	[data-column-id="sortOrder"] {
		display: none;
	}
}

.list-property-no-records {
	border-top: 4px solid #004060;
	background-color: #e9eaea;
	padding: 3px 7px;
	font-size: 1em;
}

div.property-list-table > div > div > div.gridjs-wrapper > table > thead > tr {
	> th[data-column-id="address"] > button,
	> th[data-column-id="accId"] > button {
		position: absolute;
		top: 16px;
	}
}

// div.property-list-table > div > div > div.gridjs-wrapper > table > thead > tr {
// 	> th[data-column-id="address"] > button {
// 		position: absolute;
// 		top: 16px;
// 		left: 126px;
// 	}
// }
