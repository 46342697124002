table.features-table {
	width: 100%;
	thead > tr > th {
		font-weight: 700;
		font-size: 1.2em;
		color: #004060 !important;
		text-align: left;
		padding-left: 10px;
		padding-bottom: 4px;
	}

	tbody > tr > td {
		font-weight: 700;
		color: #004060 !important;
		height: 40px;
		padding-left: 10px;
		border-top: 1px solid #dee2e6;
	}

	tbody tr:nth-child(odd) {
		background-color: #f2f2f2;
	}

	tbody > tr > td.icon {
		font-size: 1em;
	}

	thead > tr > th.center,
	tbody > tr > td.center {
		text-align: center;
	}

	color: #dadada;
}
