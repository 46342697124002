.text-upper {
  text-transform: uppercase;
}

.add-property-button-sm {
  display: none !important;
}

.add-property-button-lg {
  display: inline-flex !important;
}

.add-property-input-addon-right {
  position: relative !important;
  padding: 0px !important;
}

.add-property-search-btn {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.add-property-search-btn-gray {
  background-color: #a0a8af !important;
  color: #fff;
}

.add-property-search {
  border-left: 1px solid #004060 !important;
}

.add-property-owners-radio .chakra-radio:nth-child(even) {
  background-color: #e9eaea !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.add-property-owners-radio .chakra-radio {
  padding-left: 3px;
}

span.chakra-radio__control {
  background-color: #fff !important;
}

span[data-checked].chakra-radio__control {
  background-color: #004060 !important;
}

@media (max-width: 500px) {
  .add-property-button-lg {
    display: none !important;
  }
  .add-property-button-sm {
    display: inline-flex !important;
  }
}/*# sourceMappingURL=AddProperty.css.map */