.taxes-announcement {
  display: block;
  position: relative;
  background-color: #005087;
  color: #fff;
  padding: 10px 10px 10px 40px;
  font-weight: 300;
  font-size: 1em;
  margin: 0;
}

.taxes-announcement {
  padding: 4px 10px 10px 39px !important;
}

.taxes-announcement-light-blue {
  background-color: #deedf7 !important;
  color: #000 !important;
}

.taxes-announcement:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 30px;
  height: 30px;
  background: url(https://coewebapps.edmonton.ca/CoE.Web.UI/Images/info-white.svg) no-repeat center center;
  background-color: #193a5a;
  background-size: 80%;
}/*# sourceMappingURL=Message.css.map */