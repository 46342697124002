.assessed-value-switch-label {
	color: inherit;
	transition: color ease 0.3s;
	font-weight: 700;
}

.assessed-value-switch-label-fade {
	color: #dcddde;
	transition: color ease 0.3s;
	font-weight: 700;
}

.assessed-value-switch > span {
	border-radius: 0 !important;
	height: 20px !important;
	box-shadow: none !important;
	background-color: #cbd5e0 !important;
	padding-left: 3px;
	padding-right: 3px;
}

.assessed-value-switch > span > span {
	padding-left: 5px;
	height: 18px !important;
	margin-top: 1px;
}

.assessed-value-info-icon {
	color: #b5b5b5;
	font-size: 1.5em;
}

.assessed-value-info-icon.transparent {
	color: transparent !important;
}

.assessed-value-market-approach {
	background-color: #e9eaea !important;
	border-bottom: 3px solid #004060;
}

table.table-assessed-value > tbody > tr {
	height: 35px;
}

table.table-assessed-value > tbody > tr:hover,
table.table-assessed-value > tbody > tr:nth-child(even):hover {
	background: #d9e9f7 !important;
	transition: background ease 0.3s, color ease 0.3s;
}

table.table-assessed-value > tbody > tr.assessed-value-row-hover:hover {
	cursor: pointer !important;
}

table.table-assessed-value > tbody > tr > td {
	padding: 0px;
	border-bottom: none !important;
}

table.table-assessed-value > tbody > tr:nth-child(even) {
	background-color: #e9eaea !important;
}

table.table-assessed-value > tbody > tr > td.assessed-value-info {
	padding-left: 10px !important;
	padding-right: 10px !important;
	width: 35px;
}

table.table-assessed-value > tbody > tr > td.assessed-value-label {
	width: 30.5%;
}

table.table-assessed-value > tbody > tr > td.assessed-value-unit {
	width: 40%;
	font-weight: 700;
	padding-left: 10px !important;
	white-space: break-spaces;
}

table.table-assessed-value > tbody > tr > td.assessed-value-value {
	width: 24%;
	font-weight: 700;
}

table.table-assessed-value > tbody > tr > td.assessed-value-type {
	width: 24%;
	font-weight: 700;
}

.popover-shadow {
	-webkit-box-shadow: 5px 10px 13px -3px #b5b5b5;
	-moz-box-shadow: 5px 10px 13px -3px #b5b5b5;
	box-shadow: 5px 10px 13px -3px #b5b5b5;
}
