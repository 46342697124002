.announcement-no-data {
  display: block;
  position: relative;
  background-color: #f7f3de !important;
  color: #000 !important;
  padding: 10px 10px 10px 40px;
  font-weight: 300;
  font-size: 1em;
  margin: 0;
}

.announcement-no-data {
  padding: 4px 10px 10px 39px !important;
}

.announcement-no-data:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 30px;
  height: 30px;
  background: url(https://coewebapps.edmonton.ca/CoE.Web.UI/Images/info-white.svg) no-repeat center center;
  background-color: #b7b08d !important;
  background-size: 80%;
}/*# sourceMappingURL=DataUnavailable.css.map */