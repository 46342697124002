.navigation-container {
	width: 100%;
	height: 36px;
	background-color: #ebecee;
}

.navigation-container > .left-container {
	width: 80px;
	height: 36px;
	background-color: #e2e2e2;
	position: relative;
	float: left;
}

.navigation-container > .right-container {
	width: auto;
	text-align: right;
}

.navigation-container > .right-container > button {
	color: #004060 !important;
}

// CHAKRA OVERRIDES
.navigation-button {
	font-size: 0.9em !important;
	height: 36px !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	font-weight: 700 !important;
}

.navigation-button > svg {
	margin-right: 4px;
}

.navigation-button > span > svg.icon-right {
	margin-left: 4px;
	position: relative;
	top: -2px;
}

.navigation-mail-badge-pos {
	position: relative;
	top: -1px;
}

.navigation-mail-badge {
	background-color: #004060 !important;
	color: #ebecee !important;
	border-radius: 50% !important;
	height: 16px !important;
	min-width: 16px !important;
}

.navigation-mail-badge-text {
	text-align: center;
	position: relative;
	top: 2px;
	font-size: 0.8em;
}

button.menu-item > svg {
	margin-right: 4px;
}

button.menu-item.bar-menu-item {
	justify-content: flex-start;
}

div.menu-list {
	border-radius: 0 !important;
	padding: 0px !important;
	font-weight: 700 !important;
	position: relative;
	top: -8px;
	left: 82px;
	border: none;
	min-width: 135px !important;
	background-color: #ebecee;
}

div.menu-list > div > button.menu-item {
	border-radius: 0 !important;
	font-size: 0.9em;
	background-color: #ebecee;
	height: 36px;
	box-shadow: none !important;
	font-weight: 700 !important;
	color: #004060;
}

div.menu-list > div > button.menu-item:hover {
	background-color: #e2e2e2;
}

div.menu-list > div > p {
	color: #004060;
}

div.menu-list > div > p > svg {
	margin-left: 0px;
	padding-right: 6px;
	color: #004060;
}
