.renew-details.form-check-input > span.chakra-checkbox__control {
	border: 1px solid #00a5d4 !important;
	width: 25px;
	height: 25px;
	margin-right: 10px;
	background-color: #fff;
}

.renew-details.form-check-input > span.chakra-checkbox__control svg {
	font-size: small !important;
	color: #000;
}
