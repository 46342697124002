.my-mail-loading-pos {
  position: relative;
  top: 7px;
}

.my-mail-badge-pos {
  position: relative;
  top: 4px;
}

.my-mail-badge {
  background: #777 !important;
  color: #fff !important;
  height: 18px !important;
  min-width: 18px !important;
  border-radius: 50% !important;
}

.my-mail-badge-text {
  text-align: center;
}

.my-mail-no-records {
  border-top: 4px solid #004060;
  background-color: #e9eaea;
  padding: 3px 7px;
  font-size: 1em;
}

.my-mail-filter-container {
  border-left: 4px solid #004060;
  padding: 8px 8px 8px 8px;
  background-color: #f1f3f5;
}

.my-mail-form-label {
  font-size: 0.95em !important;
}

.my-mail-select-filter {
  height: 31px !important;
  font-size: 0.95em !important;
}

div.my-mail-table * > .gridjs-table {
  width: 100% !important;
}

div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=status] > button,
div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=mailDate] > button,
div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=corrType] > button,
div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=accId] > button {
  position: absolute;
  top: 16px;
}

div.my-mail-table > div > div > div.gridjs-wrapper > table > tbody > tr > td[data-column-id=download].gridjs-td,
div.my-mail-table > div > div > div.gridjs-wrapper > table > tbody > tr > td[data-column-id=view].gridjs-td {
  padding: 12px 5px;
}

.my-mail-grid-button {
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: 25px;
  min-width: var(--chakra-sizes-8);
  font-size: var(--chakra-fontSizes-sm);
  padding-inline-start: var(--chakra-space-3);
  padding-inline-end: var(--chakra-space-3);
  background: var(--chakra-colors-lightBlue-500);
  color: var(--chakra-colors-white);
  margin-right: 0 !important;
}

.my-mail-grid-button:hover {
  background: var(--chakra-colors-lightBlue-600);
}

@media (max-width: 500px) {
  div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=status] > button,
  div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=mailDate] > button,
  div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=corrType] > button,
  div.my-mail-table > div > div > div.gridjs-wrapper > table > thead > tr > th[data-column-id=accId] > button {
    position: absolute;
    top: 8px;
  }
}/*# sourceMappingURL=ListMail.css.map */