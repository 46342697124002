.your-property-taxes.tab-list {
	border-bottom: none !important;
}

.your-property-taxes.tab-list > button.tab {
	font-size: 0.9em !important;
	font-weight: 700 !important;
	box-shadow: none !important;
	border: 2px solid #004060;
	margin-right: 5px;
	padding: 3px 10px !important;
	background-color: #fff !important;
}

.your-property-taxes.tab-list > button.tab:hover {
	background-color: #004060 !important;
	color: #fff !important;
}

.your-property-taxes.tab-list > button.tab[aria-selected="true"] {
	background-color: #004060 !important;
	color: #fff !important;
}

button.your-property-taxes.tab.margin {
	margin-left: 6px;
	margin-right: 6px;
}

button.your-property-taxes.tab.hidden {
	display: none;
}

.your-property-taxes.tab-panel {
	padding: 0 !important;
}

.your-property-taxes-display {
	display: flex !important;
}
