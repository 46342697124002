.paperless-main-title {
    font-family: Arial;
    text-transform: uppercase;
    font-size: 2.5em;
    color: #004060;
    font-weight: 700;
}

.paperless-sub-title {
    font-size: 1.3em;
    font-weight: 700;
    color: #004060;
}