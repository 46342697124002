.announcement {
	display: block;
	position: relative;
	background-color: #005087;
	color: #fff;
	padding: 10px 10px 10px 40px;
	font-weight: 300;
	font-size: 1em;
	margin: 0;
}

/* @media screen and (min-width: 768px)
{
.announcement {
    padding: 5px 10px 10px 40px!important; 
}
} */

.announcement {
	padding: 4px 10px 10px 39px !important;
}

.announcement-light-blue {
	background-color: #deedf7 !important;
	color: #000 !important;
}

/* @media screen and (min-width: 768px) {
.announcement:before {
    width: 30px;
    height: 30px;
}
} */
.announcement:before {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	width: 30px;
	height: 30px;
	background: url(https://coewebapps.edmonton.ca/CoE.Web.UI/Images/info-white.svg) no-repeat center center;
	background-color: #193a5a;
	background-size: 80%;
}

.history.tab-list {
	border-bottom: none !important;
}

.history.tab-list > button.tab {
	font-size: 0.9em !important;
	font-weight: 700 !important;
	box-shadow: none !important;
	border: 2px solid #004060;
	margin-right: 5px;
	padding: 3px 10px !important;
	background-color: #fff !important;
}

.history.tab-list > button.tab:hover {
	background-color: #004060 !important;
	color: #fff !important;
}

.history.tab-list > button.tab[aria-selected="true"] {
	background-color: #004060 !important;
	color: #fff !important;
}

button.history.tab.margin {
	margin-left: 6px;
	margin-right: 6px;
}

.history.tab-panel {
	padding: 0 !important;
}

@media (max-width: 767px) {
	.history.tab-list > button.tab {
		margin-bottom: 5px;
		margin-right: 10px;
	}

	.history.tab-list {
		margin-top: 5px;
	}
}

/* TABLE */

.history-table > thead > tr > td {
	padding: 5px 12px;
}

.history-table > tbody > tr > td {
	padding: 7px 12px;
}

.history-table-border-all {
	/* border: 2px solid darken(#f7f7f7, 10%); */
	border-color: darken(#f7f7f7, 10%);
	border-style: solid;
	border-width: 2px;
}

.history-table-border-top {
	/* border-top: 2px solid darken(#f7f7f7, 10%); */
	border-top-color: darken(#f7f7f7, 10%);
	border-top-style: solid;
	border-top-width: 2px;
}

.history-table-border-left {
	/* border-left: 2px solid darken(#f7f7f7, 10%); */
	border-left-color: darken(#f7f7f7, 10%);
	border-left-style: solid;
	border-left-width: 2px;
}

.history-table-border-right {
	/* border-right: 2px solid darken(#f7f7f7, 10%); */
	border-right-color: darken(#f7f7f7, 10%);
	border-right-style: solid;
	border-right-width: 2px;
}

.history-table-border-bottom {
	/* border-bottom: 2px solid darken(#f7f7f7, 10%); */
	border-bottom-color: darken(#f7f7f7, 10%);
	border-bottom-style: solid;
	border-bottom-width: 2px;
}

.history-text-heading {
	font-weight: 700;
	color: #002f47;
}

.history-text-center {
	text-align: center;
}

.history-text-left {
	text-align: left !important;
}

.history-text-small {
	font-size: 0.9em;
}

.history-text-bold {
	font-weight: 700;
}

.history-light-grey-bg {
	background-color: #f7f7f7;
}

.history-year {
	text-align: center;
}

.history-amount {
	text-align: center;
}

.history-percent {
	text-align: center;
}

.history-higher-average {
	background-color: #61bcdd;
}

.history-lower-average {
	background-color: #b2c435;
}

.history-legend-container {
	position: relative;
	top: -8px;
	margin-left: 53px;
}

.history-legend-pos {
	position: relative;
	top: 5px;
	margin-right: 10px;
}

.history-legend-higher-average {
	height: 11px;
	width: 11px;
	background-color: #61bcdd;
}

.history-legend-lower-average {
	height: 11px;
	width: 11px;
	background-color: #b2c435;
}

/* @media (max-width: 455px) {
    .history-table {
        font-size: 80% !important;
    }
  }   */

.history-baseline-bg {
	background-color: #e2e2e2 !important;
}

.history-legend-container-tax {
	position: relative;
	top: -8px;
	margin-left: 63px;
}

.history-legend-container-tax.alt {
	position: relative;
	top: -10px;
	margin-left: 100px !important;
	font-family: Arial !important;
	color: #949494 !important;
}

.history-legend-container-tax.alt.following {
	position: relative;
	margin-top: -5px;
}

/* @media (max-width: 640px) {
    .history-legend-container-tax.alt.following {
        margin-top:2px;
    }
  }    */

.history-legend-font {
	font-family: Arial, Helvetica, sans-serif !important;
	font-size: 0.9em !important;
	color: #949494;
	text-align: left;
}

.history-legend-font.lighter {
	color: #c6c6c6;
}

.history-legend-pos.five-year {
	top: 7px !important;
}

.history-legend-alt-first {
	height: 5px;
	min-width: 25px;
	background-color: #597f31;
}

.history-legend-alt-last {
	height: 5px;
	min-width: 25px;
	background-color: #004060;
}

.history-legend-breakdown.first {
	height: 5px;
	min-width: 25px;
	background-color: #607a19;
}

.history-legend-breakdown.second {
	height: 5px;
	min-width: 25px;
	background-color: #b2c435;
}

.history-legend-breakdown.third {
	height: 5px;
	min-width: 25px;
	background-color: #003f60;
}

.history-legend-breakdown.last {
	height: 5px;
	min-width: 25px;
	background-color: #61bcdd;
}

.history-legend-breakdown.lighter {
	background-color: #c6c6c6;
}
