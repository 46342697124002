/* .authorized-properties.form-check-input > .chakra-checkbox__control {
	width: 25px;
	height: 25px;
	margin-right: 15px;
} */
.authorized-properties-grid th {
  z-index: 99;
  font-size: 1.1em !important;
}

.authorized-properties.form-check-input > span.chakra-checkbox__control {
  border: 1px solid #00a5d4 !important;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-color: #fff;
}

.authorized-properties.form-check-input > span.chakra-checkbox__control svg {
  font-size: small !important;
  color: #000;
}

.authorized-properties-step3-table {
  border-top: 2px solid #979797;
  border-bottom: 2px solid #979797;
}

.authorized-properties-step3-table > tbody > tr:nth-child(odd) {
  background-color: #f1f3f5;
}

a.legal-link {
  color: #444 !important;
  text-decoration: underline !important;
}

a.legal-link:hover {
  color: #444 !important;
  text-decoration: none !important;
}/*# sourceMappingURL=NewAuthorization.css.map */