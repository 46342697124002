.your-property-taxes-table {
	font-family: "Open Sans", serif !important;
	width: 100%;
}

.your-property-taxes-table > thead > tr > th,
.your-property-taxes-table > tbody > tr > td,
.your-property-taxes-table > tfoot > tr > td {
	padding: 0 !important;
	line-height: 1.5em;
}

.your-property-taxes-table > thead > tr > th[data-is-numeric="true"],
.your-property-taxes-table > tbody > tr > td[data-is-numeric="true"],
.your-property-taxes-table > tfoot > tr > td[data-is-numeric="true"] {
	text-align: end;
}

.your-property-taxes-table > thead > tr > th {
	font-size: 1.1em;
	border-bottom: 2px solid #444;
}

.your-property-taxes-table > tbody > tr > td.col-title {
	font-weight: 700;
	font-size: 1.05em;
	padding-top: 12px !important;
}

.your-property-taxes-table > tbody > tr:first-child > td.col-title {
	padding-top: 4px !important;
}

.your-property-taxes-table > tbody > tr > td.col-title {
	font-weight: 700;
	font-size: 1.05em;
	padding-top: 12px !important;
}

.your-property-taxes-table > tbody > tr > td.data-title-pad {
	padding-left: 10px !important;
}

.your-property-taxes-table > tbody > tr.row-sub-total > td {
	border-bottom: 1px solid #444;
	font-weight: 700;
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.your-property-taxes-table > tbody > tr.row-sub-total > td.data-sub-total {
	border-top: 1px solid #444;
}

.your-property-taxes-table > tbody > tr.row-sub-total > td.data-sub-total-pad {
	padding-left: 10% !important;
}

.your-property-taxes-table > tbody > tr.row-total {
	border-left: 4px solid #004060;
	background-color: #e9eaea;
	font-weight: 700;
}

.your-property-taxes-table > tbody > tr.row-total > td {
	height: 28px;
}

.your-property-taxes-table > tbody > tr.row-total > td.data-total-pad {
	padding-left: 10% !important;
}

.your-property-taxes-table > tbody > tr.row-spacer {
	height: 8px;
}

.your-property-taxes-table > tbody > tr.row-mpp {
	border-left: 4px solid #004060;
	background-color: #004060;
	color: #fff;
	font-weight: 700;
	font-size: 0.9em;
}
