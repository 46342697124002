.estimator.tab-list {
	border-bottom: none !important;
}

.estimator.tab-list > button.tab {
	font-size: 0.9em !important;
	font-weight: 700 !important;
	box-shadow: none !important;
	border: 2px solid #004060;
	margin-right: 5px;
	padding: 3px 10px !important;
	background-color: #fff !important;
}

.estimator.tab-list > button.tab:hover {
	background-color: #004060 !important;
	color: #fff !important;
}

.estimator.tab-list > button.tab[aria-selected="true"] {
	background-color: #004060 !important;
	color: #fff !important;
}

button.estimator.tab.margin {
	margin-left: 6px;
	margin-right: 6px;
}

.estimator.tab-panel {
	padding: 0 !important;
}

.estimator-value-select-container {
	color: #444 !important;
}

select.estimator-value-select > option,
select.estimator-value-threshold-select > option {
	background: #fbf5d5 !important;
	color: #444 !important;
}

select.estimator-value-select {
	border: 1px solid #506d86 !important;
	border-left: 4px solid #506d86 !important;
	border-radius: 0 !important;
	outline: none;
	background: #fbf5d5 !important;
	color: #444 !important;
	padding: 2px 35px 2px 6px !important;
	height: 31px !important;
	font-size: 0.9em !important;
	font-weight: 700;
	width: 100%;
}

select.estimator-value-select + div {
	color: #444 !important;
}

@media (max-width: 767px) {
	.estimator.tab-list > button.tab {
		margin-bottom: 5px;
		margin-right: 10px;
	}

	.estimator.tab-list {
		margin-top: 5px;
	}
}
