.breakdown-table > tbody > tr:nth-child(odd) {
  background-color: #e9eaea;
}

.breakdown-table > tbody > tr > td {
  padding: 6px 8px !important;
}

.breakdown-table > tfoot > tr {
  background-color: #004060;
  color: #fff;
}

.breakdown-table > tfoot > tr > td {
  padding: 6px 8px !important;
}

.breakdown-table > thead > tr > th[data-is-numeric=true],
.breakdown-table > tbody > tr > td[data-is-numeric=true],
.breakdown-table > tfoot > tr > td[data-is-numeric=true] {
  text-align: end;
}/*# sourceMappingURL=Breakdown.css.map */