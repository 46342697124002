table.features-table {
  width: 100%;
  color: #dadada;
}
table.features-table thead > tr > th {
  font-weight: 700;
  font-size: 1.2em;
  color: #004060 !important;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 4px;
}
table.features-table tbody > tr > td {
  font-weight: 700;
  color: #004060 !important;
  height: 40px;
  padding-left: 10px;
  border-top: 1px solid #dee2e6;
}
table.features-table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}
table.features-table tbody > tr > td.icon {
  font-size: 1em;
}
table.features-table thead > tr > th.center,
table.features-table tbody > tr > td.center {
  text-align: center;
}/*# sourceMappingURL=Features.css.map */