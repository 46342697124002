.assessment-tax-rel-blue-box {
  background-color: #004060;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  height: 80px;
}

.assessment-tax-rel-blue-box > span.text {
  position: relative;
  top: 16px;
  left: 5px;
  font-size: 1.1em;
}

.assessment-tax-rel-message-container > ol {
  padding: 13px 36px;
}/*# sourceMappingURL=AssessmentTaxRelationship.css.map */