.ceip-table {
	width: 100%;
}

.ceip-table > thead > tr > th {
	border-bottom: 2px solid #000;
	font-family: "Open Sans" !important;
	color: #000 !important;
	text-transform: none;
	font-size: 0.95em;
	line-height: 1.3;
}

.ceip-table > thead > tr > th.border-none {
	border-bottom: 0px !important;
}

.ceip-table > tbody > tr > td,
.ceip-table > tfoot > tr > td {
	padding: 4px 4px;
	border-bottom: none !important;
}

.ceip-table > thead > tr > th[data-is-numeric="true"],
.ceip-table > tbody > tr > td[data-is-numeric="true"],
.ceip-table > tfoot > tr > td[data-is-numeric="true"] {
	text-align: end;
}

.ceip-table > tbody > tr > td.text-left {
	text-align: left !important;
}

.ceip-table > tbody > tr > td.pad-left {
	padding-left: 20px !important;
}

.ceip-table > thead > tr > th,
.ceip-table > tbody > tr > td {
	text-align: center;
}

.ceip-table > thead > tr > th[data-is-numeric="true"],
.ceip-table > tbody > tr > td[data-is-numeric="true"],
.ceip-table > tfoot > tr > td[data-is-numeric="true"] {
	text-align: end;
}

.ceip-table > thead > tr > th {
	text-align: center;
}

.ceip-table > thead > tr > th.ceip-payout,
.ceip-table > tbody > tr > td.ceip-payout {
	background-color: lighten(#e59f25, 30%);
	font-weight: 700;
	padding-left: 10px;
	padding-right: 10px;
}

a.ceip-payable-with-info {
	color: #b5b5b5 !important;
	text-decoration: none !important;
}

a.ceip-payable-with-info:hover {
	color: #b5b5b5 !important;
	text-decoration: none !important;
}

.ceip-payable-with-text {
	font-weight: 700;
	color: #7d7d7d !important;
}

button.ceip-payout-button {
	background-color: darken(#e59f25, 5%) !important;
	color: #fff;
	width: 100%;
	height: 25px;
}

button.ceip-payout-button:hover {
	background-color: darken(#e59f25, 8%) !important;
}
