.tab-list {
  border-bottom: 3px solid #004060;
  margin-bottom: 8px !important;
}

button.tab {
  font-size: 1.5em;
  box-shadow: none !important;
  background-color: #e9eaea;
  color: #004060;
}

button.tab.margin {
  margin-left: 6px;
  margin-right: 6px;
}

.tab-panel {
  padding: 0 !important;
}/*# sourceMappingURL=Tabs.css.map */