.li-pay-out-table {
  width: 100%;
}

.li-pay-out-table > thead > tr > th {
  border-bottom: 2px solid #000;
  font-family: "Open Sans" !important;
  color: #000 !important;
  text-transform: none;
  font-size: 0.95em;
  line-height: 1.3;
}

.li-pay-out-table > thead > tr > th.border-none {
  border-bottom: none !important;
}

.li-pay-out-table > tbody > tr > td,
.li-pay-out-table > tfoot > tr > td {
  padding: 4px 0;
  font-family: inherit;
  text-transform: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.li-pay-out-table > thead > tr > th[data-is-numeric=true],
.li-pay-out-table > tbody > tr > td[data-is-numeric=true],
.li-pay-out-table > tfoot > tr > td[data-is-numeric=true] {
  text-align: end;
}

.li-pay-out-table > tbody > tr > td.text-left {
  text-align: left !important;
}

.li-pay-out-table > tbody > tr > td.pad-left {
  padding-left: 4px !important;
}

.li-pay-out-table > thead > tr > th,
.li-pay-out-table > tbody > tr > td {
  text-align: center;
}

.li-pay-out-submit-payment .due-date {
  background-color: #e0e8aa;
  width: 25%;
}
.li-pay-out-submit-payment .account {
  background-color: #e0e8aa;
  width: 50%;
}
.li-pay-out-submit-payment .amount {
  background-color: #b2c435;
  width: 25%;
}
.li-pay-out-submit-payment > div > div {
  padding: 10px;
  align-items: start !important;
}
.li-pay-out-submit-payment > div > div > div.value {
  margin-top: 0 !important;
}/*# sourceMappingURL=LIPayOut.css.map */