 /* .form-check-input {
	border: 1px solid #00a5d4;
	width:20px;
	height:20px;
	font-size: 0.625rem;
}  */

.preferences.form-check-input {
    width:25px;
	height:25px;
}

.preferences.form-check-input > span {
  width:25px;
height:25px;
border: 1px solid #00a5d4 !important;
}


.form-check-input[disabled]{
    background: var(--chakra-colors-gray-300);
    color: rgb(153, 152, 152);
    /* border-color: var(--chakra-colors-gray-100); */
    cursor: not-allowed;
}

a.legal-link {
    color: #444 !important;
    text-decoration: underline !important;
  }
  
  a.legal-link:hover {
    color: #444 !important;
    text-decoration: none !important;
  }

  .success-icon {
    font-size: 3.5em!important;
    color:#b2c435;
  }

 .list-property-no-records {
  border-top: 4px solid #004060;
  background-color: #e9eaea;
  padding: 3px 7px;
  font-size: 1em; 
}

svg.profile-i-icon { 
    cursor: pointer!important; 
    margin-top: -3px;
    font-size:110%;
}

.gridjs-popover-content {
  --popper-bg: var(--chakra-colors-white);
    background: var(--popper-bg);
    --popper-arrow-bg: var(--popper-bg);
    --popper-arrow-shadow-color: var(--chakra-colors-gray-200);
    width: var(--chakra-sizes-xs);
    border: 1px solid;
    border-color: inherit;
    border-radius: var(--chakra-radii-md);
    box-shadow: var(--chakra-shadows-sm);
    z-index: inherit;
}

.gridjs-popover-body {
  padding-inline-end: var(--chakra-space-3);
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  padding-inline-start: var(--chakra-space-3);
}

.gridjs-popover-content > .chakra-popover__arrow-positioner {
  left: 3px !important;
}

/* .notifications-grid td.gridjs-td {
  padding: 6px 12px;

}

.notifications-grid th.gridjs-th {
  padding: 7px 12px;

} */

