.accordion-item {
  margin-bottom: 8px;
  border: none;
}

.accordion-heading {
  background: #e9eaea;
  color: #004060;
  font-size: 1.2em !important;
  box-shadow: none !important;
  height: 44px;
  padding-inline-end: 8px !important;
  -webkit-padding-end: 8px !important;
  padding-inline-start: 8px !important;
  -webkit-padding-start: 8px !important;
}

.accordion-heading.expanded {
  background: #004060;
  color: #e9eaea;
  font-size: 1.2em !important;
  box-shadow: none !important;
}

.accordion-heading:hover {
  background: #004060 !important;
  color: #e9eaea !important;
}

.accordion-panel {
  border-top: 3px solid #b5b8b8 !important;
  padding: 0 !important;
}

.accordion-panel.grey {
  background-color: #e9eaea !important;
}

.accordion-panel.blue {
  border-top: 3px solid #fff !important;
  color: #fff !important;
  background-color: #004060 !important;
}

.accordion-expand-icon-container {
  padding: 0 6px;
}

.accordion-expand-icon-container > svg {
  position: relative;
  top: 1px;
}

.accordion-expand-icon-container.collapsed {
  background-color: #004060 !important;
  color: #e9eaea;
}

.accordion-expand-icon-container.expanded {
  background-color: #e9eaea !important;
  color: #004060;
}/*# sourceMappingURL=Accordion.css.map */