$first-sign-in-orange: #f7931d;
$first-sign-in-dark-blue: #004060;
$first-sign-in-light-blue: #00aeef;
$first-sign-in-green: #aabe4c;

.first-sign-in-bubble-container {
	min-height: 250px;
	background-image: url("./Images/bubble_bg.png");
}

.first-sign-in-add-property {
	background-image: url("./Images/add_property.png");
}

.first-sign-in-step-4-container {
	padding-left: 20px;
	padding-right: 20px;
	> img {
		width: 100%;
	}
}

.first-sign-in-notices {
	background-image: url("./Images/notices.png");
}

.first-sign-in-indicators-container {
	> div.indicator {
		height: 12px;
		width: 12px;
		background-color: #fff;
		border-radius: 50%;
		display: inline-block;

		&.active {
			background-color: $first-sign-in-dark-blue !important;
		}
	}
}

.first-sign-in-close-container {
	> button {
		background-color: $first-sign-in-orange !important;
		box-shadow: none !important;
	}
	> button:hover {
		background-color: darken($first-sign-in-orange, 10%) !important;
		box-shadow: none !important;
	}
}

.first-sign-in-orange-text {
	color: $first-sign-in-orange;
}

.first-sign-in-dark-blue-text {
	color: $first-sign-in-dark-blue;
}

.first-sign-in-light-blue-text {
	color: $first-sign-in-light-blue;
}

.first-sign-in-green-text {
	color: $first-sign-in-green;
}

.first-sign-in-modal-header {
	color: #fff !important;
	background-color: $first-sign-in-orange !important;
	border-bottom: 4px solid #eaeaea;
	padding-bottom: 0 !important;
	padding-top: 0 !important;
	padding-right: 0 !important;
}

.first-sign-in-modal-body {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	-webkit-padding-start: 0 !important;
	-webkit-padding-end: 0 !important;
}

.first-sign-in-modal-footer {
	background-color: #e9eaea;
	display: block !important;
}
