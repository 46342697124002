.header-container {
	width: 100%;
	height: 80px;
	background-color: #193a5a;
}

.header-container > .logo-container {
	width: 80px;
	height: 80px;
	background-color: #e2e2e2;
	position: relative;
	float: left;
}

.header-container > .link-container {
	width: auto;
	text-align: right;
	padding-top: 5px;
}

.header-container > .link-container > a {
	margin-right: 12px;
	color: #f4d043 !important;
	font-weight: 700;
	font-size: 0.8em;
	text-decoration: none !important;
}

.header-container > .link-container > a:hover {
	text-decoration: none !important;
}

.header-container > .logo-container > img.logo {
	width: 80px;
}
