body {
	font-family: "Open Sans", serif !important;
	color: #444 !important;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-right: 0 !important;
	padding-right: 0 !important;
	font-size: 0.95em !important;
}

.container-fluid {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

button {
	font-weight: 400 !important;
	border-radius: 0 !important;
}

input,
textarea {
	border-radius: 0 !important;
	border: 1px solid #506d86 !important;
	border-left: 5px solid #506d86 !important;
	background-color: #fbf5d5 !important;
}

textarea:focus {
	box-shadow: none !important;
}

input:focus {
	background-color: #f9f0c3 !important;
	outline: 0 !important;
	box-shadow: none !important;
}

input[type="text"]:read-only,
input[type="date"]:read-only,
input[type="number"]:read-only,
input[type="email"]:read-only,
input[type="tel"]:read-only,
input[type="time"]:read-only,
input[type="url"]:read-only,
input[type="password"]:read-only {
	background: #e5e7e9 !important;
	cursor: not-allowed;
}

a {
	color: #337ab7 !important;
	text-decoration: none !important;
}

a:focus,
a:hover {
	color: #23527c !important;
	text-decoration: underline !important;
}
a:active,
a:hover {
	outline: 0 !important;
}

.modal-header {
	color: #fff !important;
	background-color: #004060 !important;
}

.modal-footer {
	background-color: #e9eaea;
}

.modal-footer:empty {
	padding-bottom: 0px !important;
	padding-top: 10px !important;
}

.modal-footer > button {
	min-width: 100px;
}

.modal-close-button {
	color: #fff !important;
}

.modal-close-button,
.modal-close-button:hover {
	color: #e9eaea !important;
	background: #004060 !important;
	border: 1px solid #e9eaea;
	margin-top: 6px;
	outline: none !important;
}

.text-error {
	color: #c80000 !important;
	font-size: 0.8em !important;
}

.text-error.pos {
	padding-top: 4px;
}

.text-muted {
	color: #8d8d8d !important;
}

input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #8d8d8d;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: red;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: red;
}

select {
	border: 1px solid #506d86 !important;
	border-left: 5px solid #506d86 !important;
	border-radius: 0px !important;
	outline: none;
	background-color: #fbf5d5 !important;
	padding: 2px 10px 2px 6px !important;
	height: 30px;
	width: 100%;
}

select:disabled {
	background-color: #dfdfdf !important;
	cursor: not-allowed;
}

select:disabled.ignore-not-allowed {
	cursor: default !important;
}

select:disabled:hover {
	background-color: #dfdfdf !important;
	cursor: not-allowed;
}

select:disabled:focus {
	background-color: #dfdfdf !important;
	cursor: not-allowed;
}

select:hover,
select:focus {
	background-color: #fcf7db !important;
}

select > option {
	background: #fdfae9 !important;
}

.form-label-fixed {
	padding-top: 8px !important;
}

.col-no-padding-left {
	padding-left: 0 !important;
}

.col-no-padding-right {
	padding-right: 0 !important;
}

/* .chakra-input__group>input[type="text"], .chakra-input__group>input[type="textarea"], .chakra-input__group>input[type="number"], .chakra-input__group>input[type="email"], .chakra-input__group>input[type="password"] {
    border-left: 1px solid #506d86 !important;
} */

.chakra-input__group > .chakra-input__left-addon + input[type="number"] {
	border-left: 1px solid #506d86 !important;
}

.chakra-input__left-addon {
	background-color: #efecdf !important;
	border-radius: 0 !important;
	border: 1px solid #506d86 !important;
	border-left: 5px solid #506d86 !important;
	padding: 8px !important;
}

.chakra-input__right-element {
	background-color: #efecdf !important;
	border-radius: 0 !important;
	border: 1px solid #506d86 !important;
	padding: 8px !important;
	width: unset !important;
}

/* override modals for square corners */
.chakra-modal__content-container > section > * {
	border-radius: 0 !important;
}

section.chakra-modal__content {
	border-radius: 0 !important;
}

button.gridjs-sort-neutral {
	opacity: 1 !important;
	background-image: url("./components/Global/Images/sort_arrows.png") !important;
	background-position-y: center;
	background-size: 14px !important;
}

button.gridjs-sort-asc {
	background-image: url("./components/Global/Images/sort_asc.png") !important;
	background-position-y: 35%;
	background-size: 10px;
}

button.gridjs-sort-desc {
	background-image: url("./components/Global/Images/sort_desc.png") !important;
	background-position-y: 65%;
	background-size: 10px;
}
