.first-sign-in-bubble-container {
  min-height: 250px;
  background-image: url("./Images/bubble_bg.png");
}

.first-sign-in-add-property {
  background-image: url("./Images/add_property.png");
}

.first-sign-in-step-4-container {
  padding-left: 20px;
  padding-right: 20px;
}
.first-sign-in-step-4-container > img {
  width: 100%;
}

.first-sign-in-notices {
  background-image: url("./Images/notices.png");
}

.first-sign-in-indicators-container > div.indicator {
  height: 12px;
  width: 12px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}
.first-sign-in-indicators-container > div.indicator.active {
  background-color: #004060 !important;
}

.first-sign-in-close-container > button {
  background-color: #f7931d !important;
  box-shadow: none !important;
}
.first-sign-in-close-container > button:hover {
  background-color: #d97908 !important;
  box-shadow: none !important;
}

.first-sign-in-orange-text {
  color: #f7931d;
}

.first-sign-in-dark-blue-text {
  color: #004060;
}

.first-sign-in-light-blue-text {
  color: #00aeef;
}

.first-sign-in-green-text {
  color: #aabe4c;
}

.first-sign-in-modal-header {
  color: #fff !important;
  background-color: #f7931d !important;
  border-bottom: 4px solid #eaeaea;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
}

.first-sign-in-modal-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  -webkit-padding-start: 0 !important;
  -webkit-padding-end: 0 !important;
}

.first-sign-in-modal-footer {
  background-color: #e9eaea;
  display: block !important;
}/*# sourceMappingURL=FirstSignIn.css.map */