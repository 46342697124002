.banner-container {
  background-color: #e9eaea;
}

.banner-item {
  background-color: #e9eaea;
  border-bottom: 1px solid #ffffff;
}
.banner-item > div > div.banner-icon-container {
  background-color: #b7b7b7;
  color: #fff;
  min-width: 80px;
  min-height: 80px;
}
.banner-item > div > div.banner-icon-container.red {
  background-color: #cf464d !important;
}
.banner-item > div > div.banner-icon-container.orange {
  background-color: #f7931d !important;
}
.banner-item > div > div.banner-icon-container.green {
  background-color: #66b677 !important;
}
.banner-item > div > div.banner-icon-container.blue {
  background-color: #193a5a !important;
}
.banner-item > div > div.banner-icon-container > div.banner-icon {
  font-size: 34pt;
}
.banner-item > div > div.banner-text-container {
  background-color: #e9eaea;
  font-size: 0.95em !important;
  width: 100%;
}
.banner-item > div > div.banner-text-container.red {
  background-color: #ffe2e3 !important;
}
.banner-item > div > div.banner-text-container.orange {
  background-color: #ffe2c0 !important;
}
.banner-item > div > div.banner-text-container.green {
  background-color: #d5f7dd !important;
}
.banner-item > div > div.banner-text-container.blue {
  background-color: #deedf7 !important;
}
.banner-item > div > div.banner-text-container > div.banner-text {
  font-size: 1em !important;
  width: 80%;
}
.banner-item > div > div.banner-close-container {
  background-color: #e9eaea;
}
.banner-item > div > div.banner-close-container.red {
  background-color: #ffe2e3 !important;
}
.banner-item > div > div.banner-close-container.orange {
  background-color: #ffe2c0 !important;
}
.banner-item > div > div.banner-close-container.green {
  background-color: #d5f7dd !important;
}
.banner-item > div > div.banner-close-container.blue {
  background-color: #deedf7 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close {
  background-color: #b7b7b7;
  color: #fff;
}
.banner-item > div > div.banner-close-container > div.banner-close.red > button {
  background-color: #ffc3c5 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close.red > button:hover {
  background-color: #ffa5a8 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close.orange > button {
  background-color: #ffd4a1 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close.orange > button:hover {
  background-color: #ffc683 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close.green > button {
  background-color: #bbf2c8 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close.green > button:hover {
  background-color: #a2edb3 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close.blue > button {
  background-color: #c5e0f1 !important;
}
.banner-item > div > div.banner-close-container > div.banner-close.blue > button:hover {
  background-color: #add2eb !important;
}/*# sourceMappingURL=Banner.css.map */