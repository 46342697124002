a.elec-notif-link {
  color: #444 !important;
  text-decoration: underline !important;
}

a.elec-notif-link:hover {
  color: #444 !important;
  text-decoration: none !important;
}

.create-profile-legal-text {
  border: 1px solid #e9eaea;
  border-right: none !important;
  padding: 0 5px;
  height: 350px !important;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

a.create-profile-password-info {
  color: #b5b5b5 !important;
  text-decoration: none !important;
}

a.create-profile-password-info:hover {
  color: #b5b5b5 !important;
  text-decoration: none !important;
}/*# sourceMappingURL=CreateProfile.css.map */