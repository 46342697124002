.react-pdf__Page.prevPage {
	position: absolute !important;
	z-index: 1;
}

.react-pdf__Document {
	height: 800px; // change height accordingly
}

.view-pdf-container {
	border: 2px solid #eaeaea;
	border-right: none !important;
	padding: 10px;
}
