.mpp-modal-body {
	padding-left: 1.5rem !important;
	padding-right: 1.5rem !important;
	padding-bottom: 0.5rem !important;
	padding-top: 0.5rem !important;
}

.mpp-grey-container {
	background-color: #e9eaea;
}

.mpp-get-started-container {
}

.mpp-title {
	text-transform: capitalize;
}

.mpp-success-icon {
	font-size: 3.5em !important;
	color: #b2c435;
}

table.mpp-table {
	td {
		padding: 4px 0 !important;
		border: 0 !important;
	}

	td.normal,
	td.sub-total-text,
	td.sub-total-value {
		padding-top: 2px !important;
		padding-bottom: 2px !important;
	}

	td.spacer, td.spacer > p {
		padding-top: 2px !important;		
		margin-bottom: 0px !important;
	}

	td.normal > p {	
		margin-bottom: 0px !important;
	}

	td.sub-total-value {
		border-top: 1px solid #000 !important;
		vertical-align: text-top;
	}

	td.grand-total-text,
	td.grand-total-value {
		font-weight: 700;
		font-size: 1.1em;
	}

	td.grand-total-value {
		border-top: 2px solid #000 !important;
		font-weight: 700;
		vertical-align: text-top;
	}
}

.mpp-footer-title, .mpp-footer-title > p {
	margin-bottom: 0px !important;
}


