.municipal-taxes-breakdown-container {
	background-color: #e9eaea;
	padding: 10px;
}

.municipal-taxes-calc-container {
	border-left: 4px solid #004060;
	background-color: #e9eaea;
	padding: 10px;
	width: 940px;
}

.municipal-taxes-total-container {
	background-color: #b2c435;
}

.municipal-taxes-icon-container {
	background-color: #fff;
	width: 36px;
	height: 36px;
	text-align: center;
	border-radius: 36px;
	color: #004060;
}

.municipal-taxes-icon-container.times.supp.pos {
	position: relative;
	top: 17px !important;
}

.municipal-taxes-icon-container.margin {
	margin: 0 30px;
}

.municipal-taxes-icon-container.ann-supp-margin {
	margin: 0 20px;
}

.municipal-taxes-icon-container.pos {
	position: relative;
	top: 6px !important;
}

.municipal-taxes-icon-container.ann-supp-pos {
	position: relative;
	top: 17px !important;
}

.municipal-taxes-icon-container.pos.equal {
	position: relative;
	top: 20px !important;
	left: -18px;
}

.municipal-taxes-icon-container.pos.full.equal {
	position: relative;
	top: 76px !important;
	left: -17px;
}

.municipal-taxes-icon-container.pos.supp.equal {
	position: relative;
	top: 45px !important;
	left: -18px;
}

.municipal-taxes-icon > svg {
	position: relative;
	top: 7px;
	left: 1px;
	font-size: 22px;
}

.municipal-taxes-icon.equal {
	text-align: center;
	vertical-align: middle;
}

input[type="text"]:read-only.municipal-taxes-input {
	background-color: #efecdf !important;
}

input[type="text"]:read-only.municipal-taxes-input.addon {
	border-left: 1px solid #004060 !important;
}
