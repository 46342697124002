.share-your-info-grid .gridjs-notfound,
table.share-your-info-grid > tbody.gridjs-tbody > tr.gridjs-tr:hover > td.gridjs-notfound {
	background-color: #e5e7e9 !important;
}

table.share-your-info-grid th {
	text-wrap: wrap;
}

.share-your-info-grid-badge {
	border: 1px solid #f7931d;
	background-color: #f7931d;
	color: #fff;
	font-weight: 700;
	font-size: 0.8em;
	width: 65px;
	text-align: center;
}

.share-your-info-grid a.grid-link.view {
	color: #444 !important;
	text-decoration: underline !important;
}

.share-your-info-grid a.grid-link.view:hover {
	color: #23527c !important;
	text-decoration: underline !important;
	cursor: pointer;
}
