.ceip-pay-out-table {
	width: 100%;
}

.ceip-pay-out-table > thead > tr > th {
	border-bottom: 2px solid #000;
}

.ceip-pay-out-table > thead > tr > th.border-none {
	border-bottom: none !important;
}

.ceip-pay-out-table > thead > tr > th,
.ceip-pay-out-table > tbody > tr > td,
.ceip-pay-out-table > tfoot > tr > td {
	padding: 4px 0;
	font-family: inherit;
	text-transform: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
}

.ceip-pay-out-table > thead > tr > th[data-is-numeric="true"],
.ceip-pay-out-table > tbody > tr > td[data-is-numeric="true"],
.ceip-pay-out-table > tfoot > tr > td[data-is-numeric="true"] {
	text-align: end;
}

.ceip-pay-out-table > tbody > tr > td.text-left {
	text-align: left !important;
}

.ceip-pay-out-table > tbody > tr > td.pad-left {
	padding-left: 4px !important;
}

.ceip-pay-out-table > thead > tr > th,
.ceip-pay-out-table > tbody > tr > td {
	text-align: center;
}

.ceip-pay-out-submit-payment {
	.due-date {
		background-color: lighten(#e59f25, 30%);
		width: 25%;
	}
	.account {
		background-color: lighten(#e59f25, 30%);
		width: 50%;
	}
	.amount {
		background-color: #e59f25;
		width: 25%;
	}
	> div > div {
		padding: 10px;
		align-items: start !important;
	}
	> div > div > div.value {
		margin-top: 0 !important;
	}
}
