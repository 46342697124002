.assessed-value.tab-list {
  border-bottom: none !important;
}

.assessed-value.tab-list > button.tab {
  font-size: 0.9em !important;
  font-weight: 700 !important;
  box-shadow: none !important;
  border: 2px solid #004060;
  margin-right: 5px;
  padding: 3px 10px !important;
  background-color: #fff !important;
}

.assessed-value.tab-list > button.tab:hover {
  background-color: #004060 !important;
  color: #fff !important;
}

.assessed-value.tab-list > button.tab[aria-selected=true] {
  background-color: #004060 !important;
  color: #fff !important;
}

button.assessed-value.tab.margin {
  margin-left: 6px;
  margin-right: 6px;
}

button.assessed-value.tab.hidden {
  display: none;
}

.assessed-value.tab-panel {
  padding: 0 !important;
}

.assessed-value-select-threshold-pos {
  -webkit-margin-start: 0rem !important;
          margin-inline-start: 0rem !important;
}

.assessed-value-select-threshold-container {
  position: relative;
  top: -4px;
  margin: 0 !important;
  background: #fff !important;
  color: #004060 !important;
}

.assessed-value-select-threshold-container.selected {
  background: #004060 !important;
  color: #fff !important;
}

select.assessed-value-threshold-select {
  border: 2px solid #004060 !important;
  border-radius: 0 !important;
  outline: none;
  background: #fff !important;
  color: #004060 !important;
  padding: 2px 35px 2px 6px !important;
  height: 30px !important;
  font-size: 0.9em !important;
  font-weight: 700;
  width: 100%;
}

select.assessed-value-threshold-select.selected {
  background: #004060 !important;
  color: #fff !important;
}

.assessed-value-select-container {
  color: #fff !important;
}

select.assessed-value-threshold-select > option {
  background: #004060 !important;
  color: #fff !important;
}

select.assessed-value-select > option,
select.assessed-value-threshold-select > option {
  background: #004060 !important;
  color: #fff !important;
}

select.assessed-value-select {
  border: 2px solid #004060 !important;
  border-radius: 0 !important;
  outline: none;
  background: #004060 !important;
  color: #fff !important;
  padding: 2px 35px 2px 6px !important;
  height: 31px !important;
  font-size: 0.9em !important;
  font-weight: 700;
  width: 100%;
}

.assessed-value-display-md {
  display: none !important;
}

.assessed-value-display {
  display: flex !important;
}

@media (max-width: 768px) {
  .assessed-value-display-md {
    display: flex !important;
  }
  .assessed-value-display {
    display: none !important;
  }
}/*# sourceMappingURL=AssessedValue.css.map */